@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Orbitron', sans-serif;
  max-width: 100%;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden
}

body {
  position: relative;
}

.main {
  width: 100%;
  height: 100vh;

}

::-webkit-scrollbar {
  display: none;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
}

.css-ahj2mt-MuiTypography-root {
  width: 50px;
}

.css-15qvxv8 {
  width: 180px !important;
}

@media screen and (max-width: 480px) {
  .css-1cmpbu2 {
    width: 187px !important;
  }

  .css-1vjixiu {
    margin-left: -26px;
  }

  .css-1vjixiu button {
    width: 48px;
  }
}